import React from 'react'
import mbtbc from '../img/portfolio/mbtbc.webp'
import oxo from '../img/portfolio/oxo.webp'
import beniness from '../img/portfolio/beniness.webp'
import digitalw from '../img/portfolio/digitalw.webp'

const Work = () => {
    return (
        <div>
            <section className="portfolio-frl section-padding pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10">
                    <div className="sec-head">
                        <h6 className="wow fadeIn" data-wow-delay=".5s">Nos Réalisations</h6>
                        <h3 className="wow color-font">Nos récentes conceptions & <br /> Quelques projets passés.</h3>
                    </div>
                </div>
            </div>
        </div>

        <div className="container" id="nosréalisations">
            <div className="row">

                <div className="gallery full-width">

                    <div className='row'>
                        <div className="col-md-6 items graphic lg-mr wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <div className="cont">
                                    <h6>Marathon Be The Best Cotonou</h6>
                                    <p>Plateforme de gestion du marathon optimisé et éco-conçus</p>
                                </div>
                                <a href="https://marathon.ongbethebest.org" target='_blank' className="rota">
                                    <img src={mbtbc} alt="image_mbtbc" />
                                    <div className="item-img-overlay"></div>
                                </a>
                                <div className="tags">
                                    <span>
                                        <a href="" onClick={(e) => {e.preventDefault()}} >0,17 g de CO2 emis/visite</a>
                                    </span>
                                    <span>
                                        <a href="" onClick={(e) => {e.preventDefault()}} >+ vert que 83% des sites testés</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 items web wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <div className="cont">
                                    <h6>Festival OXO</h6>
                                    <p>Audit éco-conception & site éco-responsable</p>
                                </div>
                                <a href="https://oxopodfest.africa" target='_blank' className="rota">
                                    <img src={oxo} alt="image_oxo" />
                                    <div className="item-img-overlay"></div>
                                </a>
                                <div className="tags">
                                    <span>
                                        <a href="#0" onClick={(e) => {e.preventDefault()}}>0,22 g de CO2 emis/visite</a>
                                    </span>
                                    <span>
                                        <a href="#0" onClick={(e) => {e.preventDefault()}}>+ vert que 77% des sites testés</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 items web lg-mr wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <div className="cont">
                                    <h6>Benin ESS Tour by Lenvol</h6>
                                    <p>Identité de marque & site éco-responsable</p>
                                </div>
                                <a href="https://beninesstour.lenvol.bj" target='_blank' className="rota">
                                    <img src={beniness} alt="image_business" />
                                    <div className="item-img-overlay"></div>
                                </a>
                                <div className="tags">
                                    <span>
                                        <a href="#0" onClick={(e) => {e.preventDefault()}}>0,39 g de CO2 emis/visite</a>
                                    </span>
                                    <span>
                                        <a href="#0" onClick={(e) => {e.preventDefault()}}>+ vert que 58% des sites testés</a>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 items web graphic -lg-mr wow fadeInUp" data-wow-delay=".4s">
                            <div className="item-img">
                                <div className="cont">
                                    <h6>Digital Women Hereos</h6>
                                    <p>Identité de marque & site éco-responsable</p>
                                </div>
                                <a href="https://digitalwomenheroes.lenvol.bj" target='_blank' className="rota">
                                    <img src={digitalw} alt="image_digital" />
                                    <div className="item-img-overlay"></div>
                                </a>
                                <div className="tags">
                                    <span>
                                        <a href="#0" onClick={(e) => {e.preventDefault()}}>0,21 g de CO2 emis/visite</a>
                                    </span>
                                    <span>
                                        <a href="#0" onClick={(e) => {e.preventDefault()}}>+ vert que 79% des sites testés</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                        

                </div>
            </div>
        </div>
    </section>

        <section className="services section-padding position-re">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-10">
                        <div className="sec-head  text-left">
                            <h3 className="wow color-font">Ce qui nous rends unique et nécessaire</h3>
                            <p>Notre approche de l'éco-conception web intègre les problématiques de désert numérique (notamment les inégalités d’accès à internet) et contribue à ne pas rendre obsolètes les équipements plus âgés. Nous concevons des services numériques légers et peu complexes pour un meilleur confort de navigation quelque soit la qualité de la connexion internet et le terminal utilisé. Nous portons une attention particulière au design et à l’UX afin que les personnes en situation de handicap puissent consulter l’intégralité des contenus. </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                        <div className="step-item xtop">
                            <span className="icon pe-7s-medal"></span>
                            <h6>Approche 360° du branding éco-responsable</h6>
                            <p>Identité numériques, plan, et, stratégies pour promouvoir une image de marque durable et responsable.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay=".6s">
                        <div className="step-item xcolor">
                            <span className="icon pe-7s-phone"></span>
                            <h6>Approche Transformation digitale verte</h6>
                            <p>Il est indispensable de concilier transition numérique et responsabilité écologique pour un avenir plus durable.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-delay=".9s">
                        <div className="step-item xtop">
                            <span className="icon pe-7s-magic-wand"></span>
                            <h6>Approche innovation climatique</h6>
                            <p>Nous concevons  des solutions numériques pour lutter contre le changement climatique et faciliter l'adaptation climatique.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line bottom left"></div>
        </section>
    </div>
    )
}

export default Work