import React from 'react'
import '../css/plugins.css'
import '../css/style.css'


const RdvLayout = () => {
    return (
        <div>
            {/* <Nav /> */}
            <div className="calendly-inline-widget" data-url="https://calendly.com/nerdxdigital?hide_landing_page_details=1&hide_gdpr_banner=1" style={{minWidth: 320,height: '100%'}}></div>
            {/* <Footer /> */}
        </div>
    )
}

export default RdvLayout