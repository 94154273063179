import React from 'react'
import Nav from '../component/nav'
import Header from '../component/header'
import Footer from '../component/footer'
import About from '../component/about'
import Work from '../component/work'
import Expertise from '../component/expertise'
import '../css/plugins.css'
import '../css/style.css'


const HomeLayout = () => {
    return (
        <div>
            <Nav />
            <Header />
            <About />
            <Work />
            <Expertise />
            <Footer />
        </div>
    )
}

export default HomeLayout