import React from 'react'
import { WebsiteCarbonBadge } from 'react-websitecarbon-badge'

const Footer = () => {
    return (
        <footer className="sub-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="item md-mb50">
                            <div className="title">
                                <h5>Nos Coordonnées</h5>
                            </div>
                            <ul>
                                <li>
                                    <div className="cont">
                                        <h6>Notre Siège Commercial</h6>
                                        <p>Godomey Hlouacomey, Benin</p>
                                    </div>
                                </li>
                                <li>
                                    <span className="icon pe-7s-mail"></span>
                                    <div className="cont">
                                        <h6>Envoyez-nous un email</h6>
                                        <a href="mailto:contact@nerdxdigital.com">contact@nerdxdigital.</a>
                                    </div>
                                </li>
                                <li>
                                    <span className="icon pe-7s-call"></span>
                                    <div className="cont">
                                        <h6>Appellez-nous ici</h6>
                                        <p>+229 53 243 518</p>
                                        <p>+229 95 207 605</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="item md-mb50">
                            <ul>
                                <li>
                                    <div className="cont">
                                        <WebsiteCarbonBadge co2="0.12" percentage='89' />
                                    </div>
                                </li>
                                <li>
                                    <span className="icon pe-7s-mail"></span>
                                    <div className="cont">
                                        <a href="mailto:contact@nerdxdigital.com" className="butn bord curve wow fadeInUp" data-wow-delay=".5s"><span>Contactez-nous ici</span></a>
                                    </div>
                                </li>
                                <li>
                                    <span className="icon pe-7s-display1"></span>
                                    <div className="cont">
                                        <a href="https://werbsitecarbon.com" target='_blank' className="butn bord curve wow fadeInUp" data-wow-delay=".5s"><span>Tester un site web</span></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="item">
                            <div className="logo">
                                <img src="img/logo-light.png" alt="" />
                            </div>
                            <div className="social">
                                <a href="https://www.facebook.com/NerdXMarketing?mibextid=LQQJ4d" rel="noreferrer" target='_blank' ><i className="fab fa-facebook-f"></i></a>
                                <a href="https://www.linkedin.com/company/nerdxdigital/" rel="noreferrer" target='_blank' ><i className="fab fa-linkedin"></i></a>
                            </div>
                            <div className="copy-right">
                                <p>© 2023 NerdX Digital</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer