import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeLayout from './views/home';
import AboutLayout from './views/about';
import ExpertiseLayout from './views/expertise';
import RdvLayout from './views/rdv';

function App() {
  return (
	<BrowserRouter>
		<Routes>
			<Route path="/" index element={<HomeLayout/>} />
			<Route path="/about" element={<AboutLayout/>} />
			<Route path="/expertise" element={<ExpertiseLayout/>} />
			<Route path="/rdv" element={<RdvLayout/>} />
		</Routes>
	</BrowserRouter>
  );
}

export default App;
